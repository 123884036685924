import(/* webpackMode: "eager", webpackExports: ["GoogleOAuthProvider"] */ "/workspace/node_modules/@react-oauth/google/dist/index.esm.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/ChickenSans-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/ChickenSans-Bold.woff2\",\"weight\":\"800\"}],\"variable\":\"--font-chicken-sans\"}],\"variableName\":\"chickenSans\"}");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/ChickenScript_2.0.woff2\",\"weight\":\"400\",\"style\":\"normal\"}],\"variable\":\"--font-chicken-script\"}],\"variableName\":\"chickenScript\"}");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"500\"],\"subsets\":[\"latin\"],\"variable\":\"--font-roboto\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleTagManagerProvider"] */ "/workspace/src/app/contexts/google-analytics-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/workspace/src/app/contexts/session-context.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/app/globals.css");
